import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MentoringSalesPage from '../MentoringSalesPage/MentoringSalesPage'
import LeanPyramidPage from '../LeanPyramidPage/LeanPyramidPage'
import EbookPage from '../EbookPage/EbookPage'
import VirtueArmyPage from '../VirtueArmyPage/VirtueArmyPage'
import EbookDownloadPage from '../EbookDownloadPage/EbookDownloadPage'
import AuthorsRightsPage from '../PoliciesPages/AuthorsRights'
import PrivacyPolicy from '../PoliciesPages/PrivacyPolicy'
import TermsOfUse from '../PoliciesPages/TermsOfUse'

const Router = ({ children }) => {
  return (
    <BrowserRouter>
      <Routes>

        {children}
        <Route path='/' element={<MentoringSalesPage />} />
        <Route path='/lean-pyramid' element={<LeanPyramidPage />} />
        <Route path='/ebook' element={<EbookPage />} />
        <Route path='/exercito-de-virtudes' element={<VirtueArmyPage />} />
        <Route path='/byBndWlhIGRlZmluaXRpdm8gZGEgdHJhbnNmb3JtYefjbyBsZWFuIGFnaWxl' element={<EbookDownloadPage />} />
        <Route path='/politica-de-protecao-aos-direitos-autorais' element={<AuthorsRightsPage />} />
        <Route path='/termos-de-uso' element={<TermsOfUse />} />
        <Route path='/politica-de-privacidade' element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
