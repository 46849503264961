import '../../../input.css'

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import { useTranslation } from 'react-i18next'

const Class = ({ moduleId, course, courseId, openClass, setClassOpened, id }) => {
  const [t] = useTranslation()
  return (
    <div
      id={id}
      className={openClass === `module-${moduleId}-class-${courseId}` ? 'drop-shadow-xl border-2 border-cyan-600 rounded m-1 hover:bg-cyan-500 bg-cyan-600 transition-all' : 'drop-shadow-xl border-2 border-cyan-600 rounded m-1 hover:bg-cyan-600 transition-all'}
    >
      <div
        className='cursor-pointer p-2 ml-2 flex items-center justify-between rounded transition-all font-semibold text-lg '
        onClick={(event) => {
          event.stopPropagation()
          setClassOpened(`module-${moduleId}-class-${courseId}`)
        }}
      >
        <div className='flex justify-center items-center text-base text-left'>
          <PlayCircleFilledIcon fontSize='' className='text-lg mr-3' />
          {t(`${id}.title`)}
        </div>
        {/* <div
          className='flex justify-center items-center font-semibold'
          title='Tempo de duração da aula'
        >
          {course.time && (
            <>
              <AccessTimeIcon fontSize='' className='text-lg  mr-1' />
              {course.time}
            </>
          )}
        </div> */}
      </div>
      {openClass === `module-${moduleId}-class-${courseId}` && (
        <div className='px-3 py-2 list-disc bg-steelGray text-white font-medium rounded-b text-base text-left'>
          {course.items.map((item, itemId) => {
            return (
              <li key={itemId}>
                {t(`${id}.item_${itemId + 1}`)}
              </li>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Class
