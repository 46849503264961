import { useTranslation } from 'react-i18next'
import '../../input.css'

const Card = ({ title, content, id }) => {
  return (
    <div
      id={`Start-page-card-${id}`}
      className=' h-full z-10'
    >
      <div className='flex flex-col bg-steelGray rounded-3xl h-full w-full text-center sm:px-9 sm:py-8 p-6'>

        <span className='font-bold text-xl flex justify-center'>
          {id === 3 && (
            <img src='images/WarrantySeal.svg' alt='' className='drop-shadow-xl w-[25px] mr-2' />
          )}

          {title}
        </span>
        <hr className='flex self-center bg-gray-300 w-full my-4' />
        <span className=' md:text-xl font-regular sm:text-left list-disc text-slate-200 '>
          {content}
        </span>
      </div>
    </div>
  )
}
const StartPage = () => {
  const [t] = useTranslation()

  const cardContent1 = (
    <span>
      {t('mentoring.start.card_content_1')}
      <br />
      <br />
      {t('mentoring.start.card_content_2')}
      <br />
      <br />
      {t('mentoring.start.card_content_3')}
      <br />
      <br />
      {t('mentoring.start.card_content_4')}
    </span>

  )

  const cardContent2 = (
    <span>
      {t('mentoring.start.card_content_5')}
      <br />
      <br />
      {t('mentoring.start.card_content_6')}
    </span>
  )

  const cardContent3 = (
    <span>
      {t('mentoring.start.card_content_7')}
      <br />
      <br />
      {t('mentoring.start.card_content_8')}

    </span>
  )

  return (
    <div
      id='Start-page'
      className='bg-ebony flex h-full flex-col py-2 z-0'
    >
      <div className='flex flex-col lg:flex-row md:px-40 sm:px-12  '>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-19  to-gray-700'>
          <Card
            title={t('start.sofre')}
            content={cardContent1}
            id={1}
          />
        </div>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-10  to-gray-700'>
          <Card
            title={t('start.lean')}
            content={cardContent2}
            id={2}
          />
        </div>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-1  to-gray-700'>
          <Card
            title={t('start.metodo')}
            content={cardContent3}
            id={3}
          />
        </div>
      </div>
      <h1 className='p-5 py-10 text-gray-500 height-[300px]'>
        {t('mentoring.start.card_content_9')}
      </h1>
    </div>
  )
}

export default StartPage
