
import '../../input.css'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { useTranslation } from 'react-i18next'

const FeatureIcon = ({ text }) => {
  return (
    <div className='flex flex-col items-center justify-center my-3 m-2 w-[250px] '>
      <TaskAltIcon fontSize='' className='h-12 w-12 md:text-4xl text-2xl m-2' />
      <span className='text-lg md:text-xl leading-5 text-white'>
        {text}
      </span>
    </div>
  )
}

const MentoringCoverIcons = () => {
  const [t] = useTranslation()

  return (
    <div className='flex flex-wrap flex-row text-pyramid-1 justify-center items-start lg:justify-between font-medium md:px-28 md:py-10 p-2 bg-gradient-to-b from-transparent to-ebony'>
      <FeatureIcon text={t('cover.feature_1')} />
      <FeatureIcon text={t('cover.feature_2')} />
      <FeatureIcon text={t('cover.feature_3')} />
      <FeatureIcon text={t('cover.feature_4')} />
      <FeatureIcon text={t('cover.feature_5')} />
    </div>
  )
}

export default MentoringCoverIcons
