import LeanPyramidBenefitsSection from './LeanPyramidBenefitsSection'
import LeanPyramidSection from './LeanPyramidSection'
import LeanPyramidHistory from './LeanPyramidHistory'
import NavigationBar from '../NavigationBar/NavigationBar'
import Footer from '../Footer/Footer'

const LeanPyramidPage = () => {
    return (
        <div className='h-full w-full flex flex-col font-Barlow text-white  bg-steelGray'>
            <NavigationBar />
            <LeanPyramidSection />
            <LeanPyramidHistory />
            <LeanPyramidBenefitsSection />
            <Footer />

        </div>
    )
}

export default LeanPyramidPage
