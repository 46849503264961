import { useTranslation } from 'react-i18next'
import '../../input.css'

const CtaButton = () => {
  const [t] = useTranslation()
  return (
    <a
      href='https://forms.gle/XG9PZQ7qfGc8YZAw8'
      id='academy-button'
      className='flex md:justify-start justify-center'
      target='_blank'
      rel='noreferrer'
    >
      <span className='p-3 px-4 rounded-2xl text-2xl bg-pyramid-0 font-extrabold hover:bg-pyramid-1 drop-shadow-lg'>
        {t('mentoring.sticky_cta.button')}
      </span>
    </a>
  )
}

export default CtaButton
