
import '../../input.css'
import CoverPage from './MentoringCoverPage'
import ModulesSection from './ModulesSection/ModulesSection'
import HotmartSection from './HotmartSection'
import StartPage from './StartPage'
import TeacherSection from './TeacherSection'
import TestimonialsPage from './TestimonialsSection/TestimonialsPage'
// import ScarcitySection from './ScarcitySection'
import WarrantySection from './WarrantySection'
import PersonasSection from './PersonasSection'
// import OfferSection from './OfferSection'
import FaqSection from './FaqSection'
import Footer from '../Footer/Footer'
// import BonusSection from './BonusSection'
import MentoringNetflixSection from './MentoringNetflixSection'
import MentoringCoverIcons from './MentoringCoverIcons'
import NavigationBar from '../NavigationBar/NavigationBar'
import HowItWorksSection from './HowItWorksSection/HowItWorksSection'
import StickyCta from './StickyCta'

const AcademySalesPage = () => {
  return (
    <>
      <div
        id='MentoringSalesPage'
        className='h-full w-full flex flex-col font-Barlow text-white  bg-steelGray'
      >
        <NavigationBar />
        <StickyCta className='relative bottom-0' />
        <CoverPage />
        <MentoringCoverIcons />
        <StartPage />
        <HowItWorksSection />
        <PersonasSection />
        <TeacherSection />
        {/* carrousel com logsos dos clientes */}
        <TestimonialsPage />
        <MentoringNetflixSection />
        <ModulesSection />
        {/* <BonusSection /> */}
        <HotmartSection />
        {/* <OfferSection /> */}
        {/* <ScarcitySection /> */}
        <WarrantySection />
        <FaqSection />
        <div className='mb-[200px] '>
          <Footer />
        </div>

      </div>
    </>
  )
}

export default AcademySalesPage
