import '../../input.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const FaqSection = () => {
  const [t] = useTranslation()
  const questions = [
    {
      title: t('faq.question_0'),
      description: t('faq.description_0')
    },
    {
      title: t('faq.question_1'),
      description: t('faq.description_1')
    }, {
      title: t('faq.question_2'),
      description: t('faq.description_2')
    }, {
      title: t('faq.question_3'),
      description: t('faq.description_3')
    }, {
      title: t('faq.question_4'),
      description: t('faq.description_4')
    }, {
      title: t('faq.question_5'),
      description: t('faq.description_5')
    }, {
      title: t('faq.question_6'),
      description: t('faq.description_6')
    }, {
      title: t('faq.question_7'),
      description: t('faq.description_7')
    }, {
      title: t('faq.question_8'),
      description: t('faq.description_8')
    }
  ]

  const Question = ({ question, questionId, setSelectedQuestion, selectedQuestion }) => {
    return (
      <div
        id={questionId}
        className='flex flex-row hover:bg-vulcan  border-[1px] border-solid rounded-md border-pyramid-19 hover:border-1 p-5 hover:cursor-pointer'
        onClick={(e) => {
          e.preventDefault()
          setSelectedQuestion(questionId)
        }}
      >
        <div>
          <div id={questionId} className='flex flex-col text-left'>
            <span className='md:text-3xl font-bold text-lg'>
              {question.title}
            </span>
            {selectedQuestion === questionId && (
              <span className='md:text-xl text-base pt-2'>
                <hr className='flex w-full h-[1px] border-none self-center mb-3 mt-4 bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10' />
                {question.description}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  const [selectedQuestion, setSelectedQuestion] = useState('')

  const handleSetSelectedQuestion = (question) => {
    setSelectedQuestion(question)
  }

  return (
    <div className='p-[1rem] md:px-48 flex md:flex-row flex-col bg-steelGray py-12 md:py-40'>
      <div className='w-full md:sticky top-20 h-full text-left pb-5'>
        <span className='md:text-5xl text-3xl font-extrabold '>
          {t('faq.title')}
          <br />
          <ArrowForwardIcon fontSize='' className='mt-2 md:rotate-0 rotate-90 md:ml-0 ml-4' />
        </span>
      </div>
      <div className='flex flex-col w-full gap-1'>
        {questions.map((question, id) => {
          return (
            <Question key={`question-${id}`} questionId={`question-${id}`} question={question} setSelectedQuestion={handleSetSelectedQuestion} selectedQuestion={selectedQuestion} />
          )
        })}
      </div>
    </div>
  )
}

export default FaqSection
