import '../../../input.css'
import HowItWorks from './HowItWorks'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslation } from 'react-i18next'

const HowItWorksSection = () => {
  const [t] = useTranslation()

  const howitworks = [
    {
      title: t('howitworks.title_0'),
      subTitle: t('howitworks.subtitle_0'),
      quote: t('howitworks.quote_0'),
      imgUrl: 'images/howitworks/1.png'
    },
    {
      title: t('howitworks.title_1'),
      subTitle: t('howitworks.subtitle_1'),
      quote: t('howitworks.quote_1'),
      imgUrl: 'images/howitworks/2.png'
    },
    {
      title: t('howitworks.title_2'),
      subTitle: t('howitworks.subtitle_2'),
      quote: t('howitworks.quote_2'),
      imgUrl: 'images/howitworks/3.png'
    },
    {
      title: t('howitworks.title_3'),
      subTitle: t('howitworks.subtitle_3'),
      quote: t('howitworks.quote_3'),
      imgUrl: 'images/howitworks/4.png'
    }
  ]

  return (
    <div
      id='HowItWorksSection'
      className='text-black h-full md:text-base md:px-48 bg-steelGray py-24 px-[0.25rem] flex-wrap overflow-auto'
    >
      <div className='flex flex-col lg:flex-row items-start justify-center'>
        <div className='w-full'>
          <div className='flex justify-start items-start text-center flex-col lg:w-1/2'>
            <span className='md:text-left md:text-6xl text-4xl font-semibold text-white text-wrap max-w-xl'>
              {t('howitworks.title')}

            </span>
            <span className='flex min-w-[100px] min-h-[100px] md:ml-0 ml-2'>
              <ArrowForwardIcon fontSize='' className='mt-2 md:rotate-90 rotate-90 md:ml-0 ml-4 text-5xl text-white' />
            </span>
          </div>
          {howitworks.map((element, id) => {
            return (
              <HowItWorks key={`howitworks-${id}`} content={element} id={id} className='w-full' />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HowItWorksSection
