import '../../input.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { useTranslation } from 'react-i18next'

const Persona = ({ persona, personaId }) => {
  return (
    <div className='flex flex-row'>
      <span className='mx-3 pt-2 w-[60px]'>
        <PeopleAltIcon className='w-auto h-auto text-cyan-400' />
      </span>
      <div id={personaId} className='flex flex-col text-left pb-12'>

        <span className='md:text-3xl font-bold text-2xl text-'>
          {persona.title}
        </span>
        <span className='md:text-xl text-base pt-2'>
          {persona.description}
        </span>
      </div>
    </div>
  )
}

const PersonasSection = () => {
  const [t] = useTranslation()

  const personas = [{
    title: t('personas.persona_1'),
    description: t('personas.description_1')
  }, {
    title: t('personas.persona_2'),
    description: t('personas.description_2')
  }, {
    title: t('personas.persona_3'),
    description: t('personas.description_3')
  }, {
    title: t('personas.persona_4'),
    description: t('personas.description_4')
  }
  ]

  return (
    <div className='p-[1rem] md:px-48 flex md:flex-row flex-col bg-steelGray py-12 md:py-20'>
      <div className='w-full md:sticky top-20 h-full text-left pb-5'>
        <span className='md:text-5xl text-3xl font-extrabold '>
          {t('personas.title')}
          <br />
          <ArrowForwardIcon fontSize='' className='mt-2 md:rotate-0 rotate-90 md:ml-0 ml-4' />
        </span>
      </div>
      <div className='flex flex-col w-full'>
        {personas.map((persona, id) => {
          return (
            <Persona key={`persona-${id}`} personaId={id} persona={persona} />
          )
        })}
      </div>
    </div>
  )
}

export default PersonasSection
