import { useTranslation } from 'react-i18next'
import React from 'react'

// import './Footer.scss'

import EmailIcon from '@mui/icons-material/Email'
import RoomIcon from '@mui/icons-material/Room'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'

const Footer = () => {
  const [t] = useTranslation()

  return (
    <div className='bg-steelGray text-white'>
      <div className='flex flex-col bg-steelGray justify-between md:px-40'>
        <hr className=' border border-solid border-slate-600 mb-5 mx-5' />

        <div className='bg-steelGray h-16 w-full flex justify-center items-center'>
          <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-full' />
        </div>
        <div className='md:text-xl text-sm text-left p-5'>
          <span className='font-black'>
            {t('footer.aviso_legal')}
          </span>
          {t('footer.aviso')}
        </div>

        <div className='flex md:flex-row flex-col'>

          <div className='w-full p-5 text-left '>
            <div className='text-lg md:p-5'>
              Lean it 101
            </div>
            <hr className='flex w-full h-[1px] border-none self-center mb-4 mt-4 bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10' />
            <div className='md:p-5'>
              <a className='hover:text-leanItBackgroundLight' href='/politica-de-privacidade' rel='noopener noreferrer' without='true'>
                {t('footer.politica_privacidade')}
              </a>
              <br />
              <br />
              <a className='hover:text-leanItBackgroundLight' href='/politica-de-protecao-aos-direitos-autorais' rel='noopener noreferrer' without='true'>
                {t('footer.politica_autoral')}
              </a>
              <br />
              <br />
              <a className='hover:text-leanItBackgroundLight' href='/termos-de-uso' rel='noopener noreferrer' without='true'>
                {t('footer.termos_de_uso')}
              </a>
            </div>
          </div>

          <div className='w-full p-5 text-left '>
            <div className='text-lg md:p-5'>
              {t('contact.contact')}
            </div>

            <hr className='flex w-full h-[1px] border-none self-center mb-4 mt-4 bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10' />
            <div className='md:p-5 '>
              <a className='hover:text-leanItBackgroundLight' href='https://goo.gl/maps/WvgABfhARFqtAHAC8' rel='noopener noreferrer' without='true' target='_blank'>
                <h5 className=''>
                  <RoomIcon />
                  Jair Hamms,
                  <br />
                  38 - sala 302B,
                  <br />
                  88.137-084,
                  <br />
                  Palhoça, Brasil.
                </h5>
              </a>
              <br />
              <span className='hover:text-leanItBackgroundLight'>
                <a href='mailto:contact@leanit101.com'>
                  <EmailIcon /> contact@leanit101.com
                </a>
              </span>
              <br />
              <br />
              <a className='hover:text-leanItBackgroundLight' href='https://t.me/teagiliza' rel='noopener noreferrer' without='true' target='_blank'>
                <h5 className=''>
                  <TelegramIcon /> {t('contact.telegram')}
                </h5>
              </a>
              <br />
              <div className='flex flex-row gap-2'>
                <a className='hover:text-leanItBackgroundLight' href='https://twitter.com/screscencio/' rel='noopener noreferrer' without='true' target='_blank'>
                  <TwitterIcon />
                </a>
                <a className='hover:text-leanItBackgroundLight' href='https://br.linkedin.com/in/crescencio' rel='noopener noreferrer' without='true' target='_blank'>
                  <LinkedInIcon />
                </a>
                <a className='hover:text-leanItBackgroundLight' href='https://www.facebook.com/samuel.crescencio?hc_ref=ARTWp0_nXJXS_ZaLXkx0SXgIG0yHrVshjLQ6Fg9oN3x_TdK8C6nQvU9rzVKmPi_clTU&ref=nf_target' rel='noopener noreferrer' without='true' target='_blank'>
                  <FacebookIcon />
                </a>
                <a className='hover:text-leanItBackgroundLight' href='https://www.instagram.com/screscencio/' rel='noopener noreferrer' without='true' target='_blank'>
                  <InstagramIcon />
                </a>
              </div>

            </div>
            <br />

          </div>
        </div>

        <div className='italic p-3'>
          © 2023 Copyright: leanit101.com   -   Made with ♡ in Pedra Branca - Palhoça - Brazil
        </div>
      </div>
    </div>
  )
}
export default Footer
