
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

// const NavigationBar = () => {
//   const handleFlagClicked = e => {
//     e.preventDefault()

//     const language = e.target.getAttribute('data-tag')
//     i18next.changeLanguage(language)
//   }

//   const FlagLink = ({ tag, image, alt }) => (
//     <a
//       onClick={handleFlagClicked}
//       className='flex justify-center items-center'
//       href='http://leanit101.com'
//     >
//       <img
//         style={{ paddingTop: 0 }}
//         data-tag={tag}
//         className='flex h-1/2 m-1'
//         src={`/images/${image}.png`}
//         alt={alt}
//       />
//     </a>
//   )

//   const TranslationFlags = () => (
//     <div className='flex items-end justify-center list-none w-24 h-full'>
//       <li className='flex h-full w-7'>
//         <FlagLink tag='pt-BR' image='pt-br' alt='Traduza para Português' />
//       </li>
//       <li className='flex h-full w-7'>
//         <FlagLink tag='en-US' image='en-gb' alt='Translate to English' />
//       </li>
//       <li className='flex h-full w-7'>
//         <FlagLink tag='es' image='es' alt='Traducir al español' />
//       </li>
//     </div>
//   )

//   return (
//     <div className='relative top-0 mb-10'>
//       <div className='bg-vulcan h-20 w-full flex justify-center items-center fixed z-50 '>
//         <div className='bg-vulcan h-12 w-full flex justify-between items-center md:px-40 p-x-12'>
//           <div className='flex flex-row items-center justify-start h-12'>
//             <a className='h-16' href='/'>
//               <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-full' />
//             </a>
//             <a className='ml-10' href='/lean-pyramid'>
//               Lean Pyramid
//             </a>
//           </div>
//           <TranslationFlags />
//         </div>
//       </div>
//     </div>
//   )
// }
// export default NavigationBar

import { useState } from 'react'

function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false)
  const [t] = useTranslation()

  const handleFlagClicked = e => {
    e.preventDefault()

    const language = e.target.getAttribute('data-tag')
    i18next.changeLanguage(language)
  }

  const FlagLink = ({ tag, image, alt }) => (
    <a
      onClick={handleFlagClicked}
      className='flex justify-center items-center'
      href='http://leanit101.com'
    >
      <img
        style={{ paddingTop: 0 }}
        data-tag={tag}
        className='flex h-1/2 m-1'
        src={`/images/${image}.png`}
        alt={alt}
      />
    </a>
  )

  const TranslationFlags = () => (
    <div className='flex items-end justify-center list-none w-24 h-full'>
      <li className='flex h-full w-7'>
        <FlagLink tag='pt-BR' image='pt-br' alt='Traduza para Português' />
      </li>
      <li className='flex h-full w-7'>
        <FlagLink tag='en-US' image='en-gb' alt='Translate to English' />
      </li>
      <li className='flex h-full w-7'>
        <FlagLink tag='es' image='es' alt='Traducir al español' />
      </li>
    </div>
  )

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <nav className='bg-vulcan flex items-center justify-between flex-wrap p-2 fixed w-full top-0 z-50'>
      <div className='flex items-center flex-shrink-0 text-white mr-6 h-16'>
        <a className='h-16' href='/'>

          <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-full mr-2' />
        </a>
        {/* <img src={logo} alt='Logo' className='h-8 w-8 mr-2' /> */}

      </div>
      <div className='block lg:hidden'>
        <button
          className='flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white'
          onClick={toggleMenu}
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path
              d='M0 3h20v2H0zm0 6h20v2H0zm0 6h20v2H0z'
              fill='currentColor'
            />
          </svg>
        </button>
      </div>
      <div
        className={`${isOpen ? '' : 'hidden'
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
      >
        <div className='text-sm lg:flex-grow lg:flex lg:flex-row lg:items-center flex-col justify-end mr-2'>
          <a
            href='/'
            className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-white mr-4'
          >
            Home
          </a>
          <a
            href='/lean-pyramid'
            className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-white mr-4'
          >
            {t('pyramid.name')}
          </a>

          <TranslationFlags />

        </div>
      </div>
    </nav>
  )
}

export default NavigationBar
