
import '../../input.css'

import CtaButton from './CtaButton'
import { useTranslation } from 'react-i18next'

const CoverPage = () => {
  const [t] = useTranslation()

  return (
    <div
      id='CoverPage'
      className='mt-20 flex justify-between flex-col bg-gradient-to-t to-steelGray via-leanit101Blue from-leanit101BlueLight4 px-5 pt-20 pb-10 md:pt-20'
    >
      <div className='flex flex-col 2xl:flex-row items-center w-full'>
        <div className='h-full w-full flex flex-col font-bold sm:text-left items-center sm:justify-center sm:items-start sm:p-1 md:pl-28 2xl:justify-center'>

          <span className='sm:text-5xl text-2xl font-bold'>

            {t('mentoring.cover.headline')}

          </span>

          <span className='text-sm sm:text-2xl mt-4 italic font-medium '>
            {t('mentoring.cover.tagline')}

          </span>
          <div className='my-20 sm:flex-col sm:items-start hidden sm:flex animate-pulse'>
            <CtaButton />
          </div>
        </div>

        <div className='flex flex-auto flex-col justify-center items-center md:px-28 md:p-0 2xl:p-10 2xl:py-0 p-2 py-5 w-full'>
          <div className='rounded-full p-[1px] bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 drop-shadow-xl h-fit w-[70%]'>
            <img src='images/samuel.jpg' alt='' className='rounded-full' />
          </div>
          <span className='justify-center items-center font-thin mt-3 leading-5 text-lg hidden sm:flex text-gray-200 '>

            {t('mentoring.cover.mentorcall')}

          </span>
          <span className='justify-center items-center font-semibold mt-3 leading-5 text-lg hidden sm:flex'>
            {t('mentoring.cover.mentorname')}

          </span>
        </div>

        <div className='flex flex-col md:items-start sm:hidden'>
          <CtaButton />
          <span className='flex justify-center font-thin md:mt-5 mt-3 leading-5 text-lg text-gray-200 p-2'>

            {t('mentoring.cover.mentorcall')}

          </span>
          <span className='flex justify-center font-semibold md:mt-5 mt-3 leading-5 text-lg p-2'>

            {t('mentoring.cover.mentorname')}

          </span>
        </div>

      </div>

    </div>
  )
}

export default CoverPage
