import { useTranslation } from 'react-i18next'
import '../../input.css'

const StickyCta = () => {
  const [t] = useTranslation()
  return (
    <div className='fixed bottom-0 w-full flex flex-col justify-center z-30'>

      <div className='h-[100px] w-full bg-gradient-to-t from-steelGray to-transparent ' />
      <div className='bg-steelGray flex justify-center flex-row items-center px-5 py-5 gap-5'>
        <span className='md:text-2xl text-sm  text-center py-1 font-semibold'>
          {t('sticky_cta.mentoring.text_1')}
          <br />
          {t('sticky_cta.mentoring.text_2')}
        </span>
        <a
          href='https://forms.gle/XG9PZQ7qfGc8YZAw8'
          id='academy-button'
          className='flex md:justify-start justify-center'
          target='_blank'
          rel='noreferrer'
        >
          <span className='md:p-3 md:px-4  p-2 rounded-2xl md:text-2xl text-xl bg-pyramid-0 font-extrabold hover:bg-pyramid-1 drop-shadow-lg'>
            {t('sticky_cta.mentoring.button')}
          </span>
        </a>
      </div>
    </div>
  )
}

export default StickyCta
